import {
  Avatar,
  HStack,
  VStack,
  Text,
  SkeletonCircle,
  Box,
  IconButton,
  AvatarBadge,
  useToast,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  LightMode,
  Tag,
  useClipboard,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Toast } from "../Toast";
import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import { EditIcon } from "@chakra-ui/icons";
import { PersonAddIcon } from "@/assets/icons/PersonAddIcon";
import { getParticipantsInfo, removeGuest } from "@/services/room";
import { useStoreState } from "@/store";
import { User } from "@/types/user";
import { LoginModal } from "../modals/LoginModal";

type SocialSectionProps = {
  ownerId: string;
  guestsId: string[];
  maxGuests: number;
};

export const SocialSection = ({
  ownerId,
  guestsId,
  maxGuests,
}: SocialSectionProps) => {
  const currentUser = useStoreState((state) => state.currentUser.value);
  const currentRoom = useStoreState((state) => state.currentRoom.value!);
  const { listeners, manager } = currentRoom.playerState;
  const [guests, setGuests] = useState<User[]>();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onCopy } = useClipboard(window.location.href);

  useEffect(() => {
    (async () => {
      const guests = await getParticipantsInfo(guestsId);
      setGuests(guests);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!guests) return;
    const sameGuestsList = [...guests.map((guest) => guest.id)]
      .reverse()
      .every((val, idx) => guestsId[idx] === val);
    if (sameGuestsList) return;
    (async () => {
      const guests = await getParticipantsInfo(guestsId);
      setGuests(guests);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestsId, currentUser]);

  const handleShareClick = async () => {
    const url = window.location.href;
    if (Capacitor.isNativePlatform()) {
      return await Share.share({
        url,
        dialogTitle: "Invite your buddies",
      });
    }
    let nav: any = navigator;
    if (nav.share) {
      nav
        .share({
          url,
        })
        .then(() => {})
        .catch(console.error);
    } else {
      onCopy();
      toast({
        position: "top",
        render: () => <Toast type="success">Link copied to clipboard 🔥</Toast>,
      });
    }
  };

  const handleMyAvatarClick = () => {
    if (currentUser && currentUser.isGuest) {
      onOpen();
    }
  };

  const handleKickClick = async (guestId: string) => {
    await removeGuest(guestId, currentRoom);
  };

  return (
    <HStack
      spacing={5}
      overflowX="scroll"
      pt={2}
      pr={2}
      className="hiddenScrollbar"
    >
      <VStack>
        <IconButton
          aria-label="Add people"
          icon={<PersonAddIcon />}
          w="64px"
          h="64px"
          rounded="full"
          onClick={handleShareClick}
        />
        <Text fontWeight="bold" mt={2}>
          {`${guestsId.length} / ${maxGuests}`}
        </Text>
      </VStack>
      {!guests ? (
        <Box>
          <SkeletonCircle size="64px" mb="24px" />
        </Box>
      ) : (
        <>
          {guests.map((guest) => (
            <Flex key={guest.email} flexDir="column" alignItems="center">
              {currentUser?.id === guest.id ? (
                <Avatar
                  name={guest?.firstName}
                  src={guest?.photoUrl ?? undefined}
                  size="lg"
                  color="white"
                  onClick={handleMyAvatarClick}
                  cursor={currentUser.isGuest ? "pointer" : "default"}
                >
                  {guest.plan === "pro" && (
                    <LightMode>
                      <Tag
                        size="sm"
                        colorScheme="purple"
                        pos="absolute"
                        top={-2}
                        textTransform="capitalize"
                      >
                        Pro
                      </Tag>
                    </LightMode>
                  )}
                  <LoginModal isOpen={isOpen} onClose={onClose} />
                  {currentUser.isGuest ? (
                    <AvatarBadge
                      boxSize="1.1em"
                      borderWidth={0}
                      bgColor="orange.500"
                    >
                      <EditIcon size="13px" />
                    </AvatarBadge>
                  ) : (
                    <>
                      {listeners.includes(guest.id) && manager !== guest.id && (
                        <AvatarBadge boxSize="1em" borderWidth={0}>
                          <Text fontSize="xxl">🎧</Text>
                        </AvatarBadge>
                      )}
                      {manager === guest.id && (
                        <AvatarBadge boxSize="1em" borderWidth={0}>
                          <Text fontSize="xxl">🎛</Text>
                        </AvatarBadge>
                      )}
                    </>
                  )}
                </Avatar>
              ) : (
                <>
                  {currentUser?.id === ownerId ? (
                    <Menu>
                      <MenuButton pos="relative">
                        <Avatar
                          name={guest?.firstName}
                          src={guest?.photoUrl ?? undefined}
                          color="white"
                          size="lg"
                        >
                          {guest.plan === "pro" && (
                            <LightMode>
                              <Tag
                                size="sm"
                                colorScheme="purple"
                                pos="absolute"
                                top={-2}
                                textTransform="capitalize"
                              >
                                Pro
                              </Tag>
                            </LightMode>
                          )}
                        </Avatar>

                        {listeners.includes(guest.id) && manager !== guest.id && (
                          <AvatarBadge boxSize="1em" borderWidth={0}>
                            <Text fontSize="25px">🎧</Text>
                          </AvatarBadge>
                        )}
                        {manager === guest.id && (
                          <AvatarBadge boxSize="1em" borderWidth={0}>
                            <Text fontSize="25px">🎛</Text>
                          </AvatarBadge>
                        )}
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          rounded="none"
                          onClick={() => handleKickClick(guest.id)}
                        >
                          Kick
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  ) : (
                    <Avatar
                      name={guest?.firstName}
                      src={guest?.photoUrl ?? undefined}
                      color="white"
                      size="lg"
                    >
                      {guest.plan === "pro" && (
                        <LightMode>
                          <Tag
                            size="sm"
                            colorScheme="purple"
                            pos="absolute"
                            top={-2}
                            textTransform="capitalize"
                          >
                            Pro
                          </Tag>
                        </LightMode>
                      )}
                      {listeners.includes(guest.id) && manager !== guest?.id && (
                        <AvatarBadge boxSize="1em" borderWidth={0}>
                          <Text fontSize="xxl">🎧</Text>
                        </AvatarBadge>
                      )}
                      {manager === guest?.id && (
                        <AvatarBadge boxSize="1em" borderWidth={0}>
                          <Text fontSize="xxl">🎛</Text>
                        </AvatarBadge>
                      )}
                    </Avatar>
                  )}
                </>
              )}
              <Text fontWeight="bold" mt={2}>
                {guest?.firstName.split(" ")[0]}
              </Text>
            </Flex>
          ))}
        </>
      )}
    </HStack>
  );
};
