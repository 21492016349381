import React from "react";
import { Heading, Stack } from "@chakra-ui/react";
import { MusicItem, MUSIC_ITEM_TYPE } from "./music/MusicItem";
import { useStoreState } from "@/store";

export const SuggestionList = () => {
  const playlist = useStoreState((state) => state.currentRoom.value?.playlist);
  const currentSuggestions = useStoreState(
    (state) => state.currentSuggestions.value
  );

  if ((currentSuggestions?.length ?? 0) === 0) return <></>;
  return (
    <>
      <Heading mb={4}>Suggestions</Heading>
      <Stack flexDir="column" spacing={4}>
        {currentSuggestions!
          .filter(
            (music) =>
              playlist?.findIndex(
                (item) => item.youtubeId === music.youtubeId
              ) === -1
          )
          .map((music) => (
            <MusicItem
              music={{
                owner: "",
                title: music.title!,
                artist: music.artist ?? "",
                thumbnailUrl: music.thumbnailUrl!,
                youtubeId: music.youtubeId!,
                upVotes: [],
                skipVotes: [],
              }}
              type={MUSIC_ITEM_TYPE.SEARCH}
              key={music.youtubeId}
              isCurrentlyPlaying={false}
            />
          ))}
      </Stack>
    </>
  );
};
