import { AppleIcon } from "@/assets/icons/AppleIcon";
import { FacebookIcon } from "@/assets/icons/FacebookIcon";
import { GoogleIcon } from "@/assets/icons/GoogleIcon";
import {
  SocialProviders,
  socialAuthentication,
} from "@/services/authentication";
import { Capacitor } from "@capacitor/core";
import { Button, useToast, VStack } from "@chakra-ui/react";
import { captureException } from "@sentry/nextjs";
import React, { useState } from "react";
import { Toast } from "../Toast";

type LoginButtonsProps = {
  onLoad?: (isLoading: boolean) => void;
};

export const LoginButtons = ({
  onLoad: onLoading = () => {},
}: LoginButtonsProps) => {
  const [loading, setLoading] = useState<SocialProviders | undefined>();
  const toast = useToast();

  const socialLoginHandler = async (providerType: SocialProviders) => {
    try {
      onLoading(true);
      setLoading(providerType);
      await socialAuthentication(providerType);
      return;
    } catch (e) {
      if (
        (e as any).code === "auth/popup-closed-by-user" ||
        (e as any).message === "The user canceled the sign-in flow."
      ) {
        toast({
          position: "top",
          render: () => <Toast>Popup closed, try again</Toast>,
        });
      } else {
        console.error(e);
        toast({
          position: "top",
          render: () => <Toast>An error occured. 🤕 Please try again.</Toast>,
        });
        captureException(e);
      }
      setLoading(undefined);
      onLoading(false);
    }
  };

  return (
    <VStack w="full">
      <Button
        w="full"
        leftIcon={<FacebookIcon fontSize="25px" />}
        isLoading={loading === SocialProviders.Facebook}
        onClick={() => socialLoginHandler(SocialProviders.Facebook)}
      >
        Sign in with Facebook
      </Button>
      <Button
        w="full"
        leftIcon={<GoogleIcon fontSize="20px" />}
        isLoading={loading === SocialProviders.Google}
        onClick={() => socialLoginHandler(SocialProviders.Google)}
      >
        Sign in with Google
      </Button>
      {Capacitor.getPlatform() === "ios" && (
        <Button
          w="full"
          leftIcon={<AppleIcon fontSize="24px" />}
          isLoading={loading === SocialProviders.Apple}
          onClick={() => socialLoginHandler(SocialProviders.Apple)}
        >
          Sign in with Apple
        </Button>
      )}
    </VStack>
  );
};
