import React, { FormEvent, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  LightMode,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useDebounce } from "use-debounce";
import { ChevronLeftIcon, SearchIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  extractIdFromSpotifyUrl,
  getGoogleSuggestions,
} from "@/services/search";
import { isDesktopBrowser, isElectron } from "@/services/utils";
import { useStoreState } from "@/store";

export const SearchInput = () => {
  const router = useRouter();
  const [searchValue, setSearch] = useState<string>("");
  const [searchValueDebounced] = useDebounce(searchValue, 300);
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<
    string[]
  >([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [focusingSuggestionsIndex, setFocusingSuggestionsIndex] = useState<
    number | undefined
  >();
  const [showAutoCompleteList, setShowAutoCompleteList] = useState(false);
  const savedMusics = useStoreState((state) => state.savedMusics.value);

  useOutsideClick({
    ref: formRef,
    handler: () => {
      setShowAutoCompleteList(false);
    },
  });

  useEffect(() => {
    const searchFromQuery = router.query.query?.toString() ?? "";
    if (searchValue !== searchFromQuery) {
      setSearch(searchFromQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    (async () => {
      if (
        searchValueDebounced?.length >= 3 &&
        document.activeElement === inputRef.current
      ) {
        const suggestions = (
          await getGoogleSuggestions(searchValueDebounced)
        ).slice(0, 8);
        if (document.activeElement === inputRef.current)
          setAutocompleteSuggestions([...suggestions]);
      } else {
        setAutocompleteSuggestions([]);
        setFocusingSuggestionsIndex(undefined);
      }
    })();
  }, [searchValueDebounced]);

  const handleInputChange = async (event: any) => {
    setSearch(event.target.value);
    if (event.target.value === "") {
      setAutocompleteSuggestions([]);
      setFocusingSuggestionsIndex(undefined);
      setSearch("");
      return;
    }
  };

  const searchSubmitHandler = (e: FormEvent) => {
    e.preventDefault();
    setAutocompleteSuggestions([]);
    setFocusingSuggestionsIndex(undefined);
    if (searchValue.startsWith("https://open.spotify.com")) {
      router.push({
        pathname: `/${router.query.id}/search`,
        query: {
          filter: "playlists",
          query: searchValue,
          playlistId: extractIdFromSpotifyUrl(searchValue),
        },
      });
      return;
    }
    router.push({
      pathname: `/${router.query.id}/search`,
      query: {
        filter: router.query.filter ?? "musics",
        query: searchValue,
      },
    });
  };

  const clearInputHandler = () => {
    setAutocompleteSuggestions([]);
    setFocusingSuggestionsIndex(undefined);
    setSearch("");
  };

  const keypressHandler = (event: any) => {
    if (event.key === "Enter") {
      setAutocompleteSuggestions([]);
      setFocusingSuggestionsIndex(undefined);
      // @ts-ignore
      inputRef.current!.blur();
      // @ts-ignore
      inputRef.current!.value = "";
    }
    if (event.key === "ArrowDown") {
      if (focusingSuggestionsIndex === undefined) {
        setFocusingSuggestionsIndex(0);
        setSearch(autocompleteSuggestions[0]);
        return;
      }
      if (focusingSuggestionsIndex === autocompleteSuggestions.length - 1)
        return;
      setFocusingSuggestionsIndex(focusingSuggestionsIndex + 1);
      setSearch(autocompleteSuggestions[focusingSuggestionsIndex + 1]);
    } else if (event.key === "ArrowUp") {
      if (focusingSuggestionsIndex === undefined) return;
      if (focusingSuggestionsIndex === 0) {
        setFocusingSuggestionsIndex(undefined);
        return;
      }
      setFocusingSuggestionsIndex(focusingSuggestionsIndex - 1);
      setSearch(autocompleteSuggestions[focusingSuggestionsIndex - 1]);
    }
  };

  const handleInputFocus = () => {
    setShowAutoCompleteList(true);
  };

  return (
    <Box pos="relative" mb={4}>
      {router.pathname.includes("/search") &&
        (isDesktopBrowser() || isElectron()) && (
          <Button
            leftIcon={<ChevronLeftIcon />}
            mb={4}
            onClick={() => router.push(`/${router.query.id}`)}
            display={{ base: "none", sm: "flex" }}
          >
            Back to playlist
          </Button>
        )}
      <form onSubmit={searchSubmitHandler} autoComplete="off" ref={formRef}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon />
          </InputLeftElement>
          <Input
            _placeholder={{ color: "gray.100" }}
            onFocus={handleInputFocus}
            ref={inputRef}
            borderRadius="full"
            variant="filled"
            type="text"
            id="search"
            placeholder="Search for a music title or a genre..."
            value={searchValue}
            onKeyUp={keypressHandler}
            onChange={handleInputChange}
            autoCorrect="off"
            autoComplete="off"
          />
          {searchValue !== "" && (
            <InputRightElement width="3rem">
              <IconButton
                variant="ghost"
                aria-label="clear"
                h="1.75rem"
                size="sm"
                onClick={clearInputHandler}
                icon={<SmallCloseIcon />}
              />
            </InputRightElement>
          )}
        </InputGroup>
        {showAutoCompleteList && (
          <VStack
            spacing={1}
            pos="absolute"
            bgColor="gray.900"
            w="full"
            rounded="lg"
            zIndex="1"
          >
            {autocompleteSuggestions.map((suggestion, idx) => (
              <Flex
                key={idx}
                w="full"
                p={2}
                bgColor={
                  idx === focusingSuggestionsIndex ? "gray.700" : "transparent"
                }
                rounded="lg"
                onClick={() => {
                  setSearch(suggestion);
                  setAutocompleteSuggestions([]);
                  setFocusingSuggestionsIndex(undefined);
                  router.push({
                    pathname: `/${router.query.id}/search`,
                    query: {
                      filter: router.query.filter ?? "musics",
                      query: suggestion,
                      playlistId: [],
                    },
                  });
                }}
                cursor="pointer"
                _hover={{ bgColor: "gray.700" }}
              >
                {suggestion}
              </Flex>
            ))}
          </VStack>
        )}
      </form>

      {router.pathname.includes("/search") && router.query.query && (
        <HStack mt={4}>
          {router.query.filter === "musics" ? (
            <LightMode>
              <Button rounded="full" colorScheme="orange">
                Musics
              </Button>
            </LightMode>
          ) : (
            <Button
              rounded="full"
              onClick={() =>
                router.push({
                  pathname: `/[id]/search`,
                  query: { ...router.query, filter: "musics", playlistId: [] },
                })
              }
            >
              Musics
            </Button>
          )}
          {router.query.filter === "playlists" ? (
            <LightMode>
              <Button rounded="full" colorScheme="orange">
                Playlists
              </Button>
            </LightMode>
          ) : (
            <Button
              rounded="full"
              onClick={() =>
                router.push({
                  pathname: `/[id]/search`,
                  query: {
                    ...router.query,
                    filter: "playlists",
                    playlistId: [],
                  },
                })
              }
            >
              Playlists
            </Button>
          )}
          {savedMusics.length > 0 && (
            <>
              {router.query.filter === "favorites" ? (
                <LightMode>
                  <Button rounded="full" colorScheme="orange">
                    Favorites
                  </Button>
                </LightMode>
              ) : (
                <Button
                  rounded="full"
                  onClick={() =>
                    router.push({
                      pathname: `/[id]/search`,
                      query: {
                        ...router.query,
                        filter: "favorites",
                        playlistId: [],
                      },
                    })
                  }
                >
                  Favorites
                </Button>
              )}
            </>
          )}
        </HStack>
      )}
    </Box>
  );
};
