import { db } from "@/libs/firebase";
import { PlayerState } from "@/types/room";
import firebase from "firebase/app";

export const updatePlayerState = (roomId: string, playerState: PlayerState) =>
  db.collection("rooms").doc(roomId).update({
    "playerState.secondsElapsed": playerState.secondsElapsed,
    "playerState.paused": playerState.paused,
    modifiedDate: new Date(),
  });

export const removeMusicListener = (roomId: string, userId: string) =>
  db
    .collection("rooms")
    .doc(roomId)
    .update({
      "playerState.listeners":
        firebase.firestore.FieldValue.arrayRemove(userId),
      modifiedDate: new Date(),
    });

export const addMusicListener = (roomId: string, userId: string) =>
  db
    .collection("rooms")
    .doc(roomId)
    .update({
      "playerState.listeners": firebase.firestore.FieldValue.arrayUnion(userId),
      modifiedDate: new Date(),
    });

export const delegateManagement = (
  roomId: string,
  userId: string,
  newManagerId: string
) =>
  db
    .collection("rooms")
    .doc(roomId)
    .update({
      "playerState.listeners":
        firebase.firestore.FieldValue.arrayRemove(userId),
      "playerState.manager": newManagerId,
      modifiedDate: new Date(),
    });

export const takeManagement = (roomId: string, userId: string) =>
  db
    .collection("rooms")
    .doc(roomId)
    .update({
      "playerState.listeners": firebase.firestore.FieldValue.arrayUnion(userId),
      "playerState.manager": userId,
      modifiedDate: new Date(),
    });
