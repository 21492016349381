import React, { useEffect, useState } from "react";
import {
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
  Text,
  Flex,
  HStack,
  useToast,
} from "@chakra-ui/react";

import { Button, IconButton, LightMode, Avatar, Menu } from "@chakra-ui/react";
import { SkipIcon } from "@/assets/icons/SkipIcon";
import { VolumeHighIcon } from "@/assets/icons/VolumeHighIcon";
import { FadeInImage } from "@/components/FadeInImage";
import { LoginModal } from "@/components/modals/LoginModal";
import { PayTokensModal } from "@/components/modals/PayTokensModal";
import { Toast } from "@/components/Toast";
import { getUserById } from "@/services/authentication";
import {
  userHasUpVoted,
  userHasSkipVoted,
  addMusicToPlaylist,
  upVoteMusic,
  skipVoteMusic,
  skipMusic,
  removeMusicFromPlaylist,
  isRoomOwner,
} from "@/services/room";
import {
  insertToken,
  addSavedMusic,
  removeSavedMusic,
  isGod,
} from "@/services/user";
import { useStoreState, useStoreActions } from "@/store";
import { Music } from "@/types/room";
import { User } from "@/types/user";
import { StarIcon, ArrowUpIcon, SmallAddIcon } from "@chakra-ui/icons";
import { captureException } from "@sentry/nextjs";

export enum MUSIC_ITEM_TYPE {
  SEARCH,
  PLAYLIST,
  LIBRARY,
}

type MusicProps = {
  music: Music;
  isCurrentlyPlaying: boolean;
  type: MUSIC_ITEM_TYPE;
};

export const MusicItem: React.FC<MusicProps> = ({
  type,
  music,
  isCurrentlyPlaying,
}: MusicProps) => {
  const currentRoom = useStoreState((state) => state.currentRoom.value!);
  const currentUser = useStoreState((state) => state.currentUser.value!);
  const { setUser } = useStoreActions((action) => action.currentUser);
  const savedMusics = useStoreState((state) => state.savedMusics.value);
  const setSavedMusics = useStoreActions(
    (action) => action.savedMusics.setSavedMusics
  );
  const [added, setAdded] = useState(false);
  const [upVoted, setUpVote] = useState(false);
  const [skipVoted, setSkipVoted] = useState(false);
  const [musicOwner, setMusicOwner] = useState<User | undefined>();
  const [addLoading, setAddLoading] = useState(false);
  const {
    onOpen: onTooltipOpen,
    isOpen: isTooltipOpen,
    onClose: onTooltipClose,
  } = useDisclosure();
  const {
    onOpen: onLoginModalOpen,
    isOpen: isLoginModalOpen,
    onClose: onLoginModalClose,
  } = useDisclosure();
  const {
    isOpen: isTokensModalOpen,
    onOpen: onTokensModalOpen,
    onClose: onTokensModalClose,
  } = useDisclosure();
  const [isFavorite, setIsFavorite] = useState(
    savedMusics.findIndex(
      (savedMusic) => savedMusic.youtubeId === music.youtubeId
    ) !== -1
  );
  const toast = useToast();

  const [skipToolTipData, setSkipToolTipData] = useState({
    totalParticipants: 0,
    totalRemainingSkipVotes: 0,
    totalSkipVotes: 0,
  });

  useEffect(() => {
    if (type !== MUSIC_ITEM_TYPE.PLAYLIST) return;
    (async () => {
      if (!music.owner) return;
      setMusicOwner(await getUserById(music.owner));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === MUSIC_ITEM_TYPE.PLAYLIST) {
      setUpVote(userHasUpVoted(currentUser.id, music));
      setSkipVoted(userHasSkipVoted(currentUser.id, music));
    }
  }, [setUpVote, currentUser.email, currentUser.id, music, type]);

  const handleAddMusic = async () => {
    setAddLoading(true);
    if (
      !isGod(currentUser.id) &&
      currentRoom.owner !== currentUser.id &&
      currentRoom.type === "bar"
    ) {
      if (
        currentRoom.tokenPrice > 0 &&
        (currentUser.tokens[currentRoom.id] ?? 0) > 0
      ) {
        const result = await insertToken(currentUser.id, currentRoom.id);
        if (result !== "success") {
          toast({ description: "Couldn't spend a token" });
          return;
        }
        const updatedUser: any = { ...currentUser };
        updatedUser.tokens[currentRoom.id] =
          updatedUser.tokens[currentRoom.id] - 1;
        setUser({ ...updatedUser });
      } else {
        setAddLoading(false);
        onTokensModalOpen();
        return;
      }
    }
    try {
      await addMusicToPlaylist(currentRoom, music, currentUser);
      toast({
        render: () => <Toast type="success">Music added to playlist 🔥</Toast>,
      });
    } catch (e) {
      setAdded(false);
      captureException(e);
      setAddLoading(false);
    }
  };

  const handleUpvoteMusic = async () => {
    setUpVote(!upVoted);
    await upVoteMusic(currentUser.id, currentRoom, music);
  };

  const handleSkipVoteMusic = async () => {
    setSkipVoted(!skipVoted);
    await skipVoteMusic(currentUser.id, currentRoom, { ...music });
    const totalParticipants = currentRoom.guests.length;
    const totalSkipVotes = music.skipVotes.length;
    let totalRemainingSkipVotes;
    if (totalParticipants > 2) {
      totalRemainingSkipVotes =
        Math.ceil(totalParticipants / 2) - totalSkipVotes;
    } else if (totalParticipants === 2) {
      totalRemainingSkipVotes = 2 - totalSkipVotes;
    } else {
      totalRemainingSkipVotes = 0;
    }
    setSkipToolTipData({
      totalParticipants,
      totalSkipVotes,
      totalRemainingSkipVotes,
    });
    if (totalRemainingSkipVotes <= 0 || totalParticipants === 1) {
      return skipMusic(currentRoom);
    }
    if (!skipVoted) onTooltipOpen();
  };

  const isMusicOwner =
    type === MUSIC_ITEM_TYPE.PLAYLIST &&
    (currentUser.id === music.owner ||
      isRoomOwner(currentUser.id, currentRoom) ||
      isGod(currentUser.id));

  const handleRemoveClick = async () =>
    removeMusicFromPlaylist(currentRoom, music.youtubeId);

  const handleFavoriteClick = async () => {
    if (currentUser.isGuest) return onLoginModalOpen();
    setIsFavorite(!isFavorite);
    const musicToSave = {
      artist: music.artist,
      thumbnailUrl: music.thumbnailUrl,
      title: music.title,
      youtubeId: music.youtubeId,
      addedDate: new Date(),
    };
    try {
      if (!isFavorite) {
        await addSavedMusic(currentUser.id, [...savedMusics, musicToSave]);
        setSavedMusics([...savedMusics, musicToSave]);
      } else {
        savedMusics.splice(
          savedMusics.findIndex(
            (savedMusic) => savedMusic.youtubeId === musicToSave.youtubeId
          ),
          1
        );
        await removeSavedMusic(currentUser.id, [...savedMusics]);
        setSavedMusics([...savedMusics]);
      }
    } catch (e) {
      console.error(e);
      captureException(e);
      setIsFavorite(!isFavorite);
    }
  };

  const attributeActionElement = (type: MUSIC_ITEM_TYPE) => {
    switch (type) {
      case MUSIC_ITEM_TYPE.LIBRARY:
        return <></>;
      case MUSIC_ITEM_TYPE.PLAYLIST:
        return (
          <div>
            {isCurrentlyPlaying ? (
              <HStack>
                <Button
                  onClick={handleFavoriteClick}
                  size="sm"
                  _focus={{ outline: "none" }}
                  color={isFavorite ? "yellow.400" : "white"}
                >
                  <StarIcon size="20" />
                </Button>
                <Tooltip
                  defaultIsOpen
                  label={`Still need ${skipToolTipData.totalRemainingSkipVotes} skip votes.`}
                  aria-label="Skipvotes remaining"
                  onClose={onTooltipClose}
                  isOpen={isTooltipOpen}
                  closeDelay={3000}
                  placement="top"
                  hasArrow
                >
                  <Button
                    onClick={handleSkipVoteMusic}
                    size="sm"
                    color={skipVoted ? "green.200" : "white"}
                  >
                    <SkipIcon boxSize="20px" mr="1" />
                    {music.skipVotes!!.length}
                  </Button>
                </Tooltip>
              </HStack>
            ) : (
              <Button onClick={handleUpvoteMusic} size="sm">
                <ArrowUpIcon
                  boxSize="20px"
                  color={upVoted ? "orange.400" : "white"}
                  mr="1"
                />
                {music.upVotes!!.length}
              </Button>
            )}
          </div>
        );
      case MUSIC_ITEM_TYPE.SEARCH:
        return (
          <LightMode>
            <IconButton
              size="sm"
              colorScheme="orange"
              aria-label="add"
              icon={<SmallAddIcon boxSize="20px" />}
              onClick={handleAddMusic}
              isLoading={addLoading}
            />
          </LightMode>
        );
    }
  };
  return (
    <div className="music-item">
      <PayTokensModal
        onClose={onTokensModalClose}
        isOpen={isTokensModalOpen}
        user={currentUser}
        room={{ id: currentRoom.id, tokenPrice: currentRoom.tokenPrice }}
      />
      <LoginModal
        title="You need to sign in so we can save this music for you."
        isOpen={isLoginModalOpen}
        onClose={onLoginModalClose}
      />
      <div className="album-cover">
        {isCurrentlyPlaying && (
          <Flex
            pos="absolute"
            backgroundColor="rgba(0,0,0,0.5)"
            w="full"
            h="full"
            justify="center"
            align="center"
            rounded="md"
          >
            <VolumeHighIcon boxSize="30px" />
          </Flex>
        )}
        <FadeInImage
          boxSize="70px"
          src={music.thumbnailUrl}
          rounded="md"
          objectFit="cover"
        />
        {type === MUSIC_ITEM_TYPE.PLAYLIST &&
          musicOwner &&
          !musicOwner.isGuest && (
            <Avatar
              position="absolute"
              name={musicOwner?.firstName}
              src={musicOwner?.photoUrl ?? undefined}
              w="35px"
              h="35px"
              bottom="-10px"
              right="-10px"
              color="white"
            />
          )}
      </div>
      <div
        className={
          isCurrentlyPlaying ? "music-info currently-playing" : "music-info"
        }
      >
        {isMusicOwner ? (
          <Menu>
            <MenuButton as={Flex} flexDir="column" minW="0">
              <Text
                fontWeight="bold"
                textAlign="left"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {music.title}
              </Text>
              <Text
                textAlign="left"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {music.artist}
              </Text>
            </MenuButton>
            <MenuList>
              <MenuItem
                rounded="none"
                color="white"
                onClick={handleRemoveClick}
              >
                Remove
              </MenuItem>
            </MenuList>
          </Menu>
        ) : (
          <Flex flexDir="column" minW="0">
            <Text
              fontWeight="bold"
              textAlign="left"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {music.title}
            </Text>
            <Text
              textAlign="left"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {music.artist}
            </Text>
          </Flex>
        )}
        {attributeActionElement(type)}
      </div>
    </div>
  );
};
