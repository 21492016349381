import { Stack, Button, Box, Flex, DarkMode } from "@chakra-ui/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { getFirebaseCloudFunctionsBaseUrl } from "../services/utils";

type Props = {
  onPaymentDone: (error?: string) => void;
  totalTokens: number;
  price: number;
  userId: string;
  roomId: string;
};

export const TokensPaymentForm = ({
  onPaymentDone,
  totalTokens,
  price,
  userId,
  roomId,
}: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentLoading, setPaymentLoading] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      if (!stripe || !elements)
        throw new Error("stripe or elements not found.");
      setPaymentLoading(true);
      const card = elements.getElement(CardElement);
      if (!card) throw new Error("card not found");
      const { token } = await stripe.createToken(card);
      if (!token) throw new Error("token not found");
      await pay({
        stripe,
        token: token.id,
        amount: totalTokens,
        userId,
        roomId,
      });
      onPaymentDone();
    } catch (err) {
      console.error(err);
      setPaymentLoading(false);
      onPaymentDone((err as Error).toString() ?? "server_error");
    }
  };

  return (
    <Stack as="form" onSubmit={handleSubmit}>
      <Box
        py={3}
        px={3}
        borderColor="rgba(255, 255, 255, 0.16)"
        borderWidth="1px"
        rounded="lg"
      >
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                backgroundColor: "transparent",
                padding: "2rem",
                color: "white",
                "::placeholder": {
                  color: "#adadad",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
      </Box>

      <Flex justifyContent="flex-end">
        <DarkMode>
          <Button
            type="submit"
            isDisabled={!stripe}
            isLoading={paymentLoading}
            colorScheme="orange"
          >
            Pay {totalTokens * (price / 100) * (totalTokens === 1 ? 1 : 0.9)}€
          </Button>
        </DarkMode>
      </Flex>
    </Stack>
  );
};

const pay = async ({
  stripe,
  token,
  amount,
  userId,
  roomId,
}: {
  stripe: Stripe;
  token: string;
  amount: number;
  userId: string;
  roomId: string;
}) =>
  new Promise<void>(async (resolve, reject) => {
    if (!stripe) return;
    console.log({ amount, userId, roomId });
    const response = await fetch(
      `${getFirebaseCloudFunctionsBaseUrl()}/createPaymentIntent`,
      {
        method: "POST",
        body: JSON.stringify({ amount, userId, roomId }),
      }
    ).catch((err) => {
      console.error("Error while calling `createPaymentIntent`", err);
      reject(err);
    });
    if (!response) return;
    const result = await stripe.confirmCardPayment(
      (
        await response.json()
      ).clientSecret,
      {
        payment_method: { card: { token } },
      }
    );
    if (result.error) {
      console.error(
        "Error while calling `confirmCardPayment`",
        result.error.message
      );
      reject(result.error.code);
      return;
    }
    if (result.paymentIntent.status === "succeeded") {
      resolve();
    }
  });
