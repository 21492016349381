import React, { useEffect, useState } from "react";
import {
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Stack,
  HStack,
  IconButton,
  Button,
  LightMode,
  Progress,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FadeInImage } from "@/components/FadeInImage";
import { Loading } from "@/components/Loading";
import { MusicVideo, PlaylistPreview } from "@/libs/ytMusic/models";
import { addPlaylistToPlaylist } from "@/services/room";
import {
  getYoutubeSearchResults,
  listSpotifyPlaylistMusics,
  searchForSpotifyPlaylists,
} from "@/services/search";
import { useStoreState } from "@/store";
import { MusicItem, MUSIC_ITEM_TYPE } from "../../music/MusicItem";
import { SuggestionList } from "../../SuggestionList";

export const SearchResults = () => {
  const router = useRouter();
  const currentRoom = useStoreState((state) => state.currentRoom.value);
  const currentUser = useStoreState((state) => state.currentUser.value);
  const [musicResults, setMusicResults] = useState<MusicVideo[]>([]);
  const [playlistMusicResults, setPlaylistMusicResults] = useState<
    MusicVideo[]
  >([]);

  const [playlistResults, setPlaylistResults] = useState<PlaylistPreview[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isParsingPlaylist, setIsParsingPlaylist] = useState(false);
  const [parsingProgressValue, setParsingProgressValue] = useState(0);

  const { playlist } = useStoreState((state) => state.currentRoom.value!);
  const [currentSearch, setCurrentSearch] = useState({
    musics: "",
    playlists: "",
  });
  const [currentPlaylistId, setCurrentPlaylistId] = useState("");
  const savedMusics = useStoreState((state) => state.savedMusics.value);
  const [isAddWholePlaylistLoading, setIsAddWholePlaylistLoading] =
    useState(false);

  useEffect(() => {
    const searchFromQuery = router.query.query?.toString() ?? "";
    const playlistIdFromQuery = router.query.playlistId?.toString();
    const filterFromQuery = router.query.filter?.toString();
    if (!filterFromQuery) return;
    switch (filterFromQuery) {
      case "musics": {
        if (
          searchFromQuery === "" ||
          (currentSearch.musics === searchFromQuery && musicResults.length > 0)
        )
          return;
        setCurrentSearch({ ...currentSearch, musics: searchFromQuery });
        setSearchLoading(true);
        getYoutubeSearchResults(searchFromQuery).then((musicItems) => {
          setMusicResults(musicItems);
          setSearchLoading(false);
        });
        break;
      }
      case "playlists": {
        if (playlistIdFromQuery && playlistIdFromQuery !== currentPlaylistId) {
          setSearchLoading(true);
          setIsParsingPlaylist(true);
          setCurrentPlaylistId(playlistIdFromQuery);
          let value = 0;
          const interval = setInterval(() => {
            setParsingProgressValue(value);
            if (value < 100) value += 2;
          }, 1000);
          listSpotifyPlaylistMusics(playlistIdFromQuery).then((musicItems) => {
            setPlaylistMusicResults([...musicItems]);
            setSearchLoading(false);
            setIsParsingPlaylist(false);
            clearInterval(interval);
            setParsingProgressValue(0);
          });
          return;
        }
        if (
          searchFromQuery === "" ||
          (currentSearch.playlists === searchFromQuery &&
            playlistResults.length > 0)
        )
          return;
        setCurrentSearch({ ...currentSearch, playlists: searchFromQuery });
        setSearchLoading(true);
        searchForSpotifyPlaylists(searchFromQuery).then((playlistItems) => {
          setPlaylistResults(playlistItems);
          setSearchLoading(false);
        });
        break;
      }
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const onAddTheWholePlaylistClick = async () => {
    if (!currentRoom) return;
    setIsAddWholePlaylistLoading(true);
    await addPlaylistToPlaylist(
      currentRoom,
      filteredPlaylistMusicResults.map((music) => ({
        owner: "",
        title: music.title!,
        artist: music.artist ?? "",
        thumbnailUrl: music.thumbnailUrl!,
        youtubeId: music.youtubeId!,
        upVotes: [],
        skipVotes: [],
      })),
      currentUser
    );
    setIsAddWholePlaylistLoading(false);
    router.push(`/${router.query.id}`);
  };

  const filterMusic = (music: MusicVideo) =>
    playlist?.findIndex((item) => item.youtubeId === music.youtubeId) === -1;

  const filteredMusicResults = musicResults.filter(filterMusic);

  const filteredPlaylistMusicResults = playlistMusicResults.filter(filterMusic);

  return (
    <>
      {(musicResults.length > 0 || playlistResults.length > 0) && (
        <HStack mb={4}>
          {router.query.playlistId && (
            <IconButton
              icon={<ArrowBackIcon fontSize="25px" />}
              aria-label="back"
              size="lg"
              variant="ghost"
              onClick={() => router.back()}
            />
          )}
          <Heading>Results</Heading>
        </HStack>
      )}
      {searchLoading ? (
        <VStack spacing="6">
          {!isParsingPlaylist && <Loading style={{ marginTop: "20px" }} />}
          {isParsingPlaylist && (
            <VStack spacing="4" w="70%">
              <Text>Fetching the playlist...</Text>
              <LightMode>
                <Progress
                  value={parsingProgressValue}
                  colorScheme="orange"
                  rounded="md"
                  w="full"
                  size="xs"
                />
              </LightMode>
            </VStack>
          )}
        </VStack>
      ) : (
        <>
          {filteredMusicResults.length === 0 &&
          currentSearch.musics === "" &&
          filteredPlaylistMusicResults.length === 0 ? (
            <SuggestionList />
          ) : (
            <Text textAlign="center">
              {filteredMusicResults.length === 0 &&
              filteredPlaylistMusicResults.length === 0
                ? "No musics found 🤷‍♂️"
                : ""}
            </Text>
          )}
          {router.query.filter === "musics" && (
            <Stack flexDir="column" spacing={4}>
              {filteredMusicResults.map((music) => (
                <MusicItem
                  music={{
                    owner: "",
                    title: music.title!,
                    artist: music.artist ?? "",
                    thumbnailUrl: music.thumbnailUrl!,
                    youtubeId: music.youtubeId!,
                    upVotes: [],
                    skipVotes: [],
                  }}
                  type={MUSIC_ITEM_TYPE.SEARCH}
                  key={music.youtubeId}
                  isCurrentlyPlaying={false}
                />
              ))}
            </Stack>
          )}
          {router.query.filter === "playlists" && (
            <>
              {router.query.playlistId ? (
                <Stack flexDir="column" spacing={4}>
                  {currentRoom?.owner === currentUser?.id && (
                    <LightMode>
                      <Button
                        colorScheme="orange"
                        onClick={onAddTheWholePlaylistClick}
                        isLoading={isAddWholePlaylistLoading}
                      >
                        Add the whole playlist
                      </Button>
                    </LightMode>
                  )}
                  {filteredPlaylistMusicResults.map((music) => (
                    <MusicItem
                      music={{
                        owner: "",
                        title: music.title!,
                        artist: music.artist ?? "",
                        thumbnailUrl: music.thumbnailUrl!,
                        youtubeId: music.youtubeId!,
                        upVotes: [],
                        skipVotes: [],
                      }}
                      type={MUSIC_ITEM_TYPE.SEARCH}
                      key={music.youtubeId}
                      isCurrentlyPlaying={false}
                    />
                  ))}
                </Stack>
              ) : (
                <>
                  <SimpleGrid columns={{ base: 2, sm: 3 }} spacing={4}>
                    {playlistResults.map((playlist) => (
                      <VStack
                        cursor="pointer"
                        onClick={() =>
                          router.push({
                            pathname: "/[id]/search",
                            query: {
                              ...router.query,
                              playlistId: playlist.playlistId,
                            },
                          })
                        }
                        key={playlist.playlistId}
                      >
                        <FadeInImage
                          boxSize="180px"
                          objectFit="cover"
                          src={playlist.thumbnailUrl}
                          alt={playlist.title}
                          rounded="md"
                        />
                        <Text textAlign="center" noOfLines={1}>
                          {playlist.title}
                        </Text>
                      </VStack>
                    ))}
                  </SimpleGrid>
                  {playlistResults.length === 0 && (
                    <Text textAlign="center">No playlist found 🤷‍♂️</Text>
                  )}
                </>
              )}
            </>
          )}
          {router.query.filter === "favorites" && (
            <Stack flexDir="column" spacing={4}>
              {savedMusics.filter(filterMusic).map((music) => (
                <MusicItem
                  music={{
                    owner: "",
                    title: music.title,
                    artist: music.artist ?? "",
                    thumbnailUrl: music.thumbnailUrl!,
                    youtubeId: music.youtubeId,
                    upVotes: [],
                    skipVotes: [],
                  }}
                  type={MUSIC_ITEM_TYPE.SEARCH}
                  key={music.youtubeId}
                  isCurrentlyPlaying={false}
                />
              ))}
              {savedMusics.length === 0 && (
                <Text textAlign="center">No musics saved in favorites 🤷‍♂️</Text>
              )}
            </Stack>
          )}
        </>
      )}
    </>
  );
};
