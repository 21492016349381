import { Toast } from "@/components/Toast";
import { updatePlayerState } from "@/services/player";
import { removeGuest, skipMusic } from "@/services/room";
import { useStoreState } from "@/store";
import { Music, Room, PlayerState } from "@/types/room";
import { User } from "@/types/user";
import { useToast } from "@chakra-ui/toast";
import React, { useEffect, useRef } from "react";
import YouTube from "react-youtube";

type PlayerProps = {
  music: Music;
};

enum PlayerStates {
  ENDED,
  PLAYING,
  PAUSED,
  BUFFERING,
  VIDEO_CUED,
  OTHER,
}

export const Player = ({ music }: PlayerProps) => {
  const currentUser = useStoreState((state) => state.currentUser.value as User);
  const currentRoom = useStoreState((state) => state.currentRoom.value as Room);
  const playerStateFromDb = useRef<PlayerState>(currentRoom.playerState);
  const playerRef = useRef<any>(null);
  const healthCount = useRef<number>(0);
  const toast = useToast();
  const opts: any = {
    playerVars: {
      autoplay: 0,
      rel: 0,
      playsinline: 1,
    },
  };

  useEffect(() => {
    if (currentRoom.type === "bar") return;
    const controlInterval = setInterval(async () => {
      if (
        currentUser.id !== playerStateFromDb.current.manager ||
        !playerRef.current
      )
        return;
      const secondsElapsed = Math.round(playerRef.current.getCurrentTime());
      const secondsElapsedFromDb = playerStateFromDb.current.secondsElapsed;
      const playerState = playerRef.current.getPlayerState();
      const newPlayerState = { ...playerStateFromDb.current };
      newPlayerState.paused = playerState === PlayerStates.PAUSED;
      if (Math.abs(secondsElapsed - secondsElapsedFromDb) > 19) {
        newPlayerState.secondsElapsed = secondsElapsed;
      }
      if (
        newPlayerState.paused !== playerStateFromDb.current.paused ||
        newPlayerState.secondsElapsed !==
          playerStateFromDb.current.secondsElapsed
      ) {
        playerStateFromDb.current = { ...newPlayerState };
        await updatePlayerState(currentRoom.id, { ...newPlayerState });
      }
    }, 5000);

    const healthCheckingInterval = setInterval(async () => {
      if (
        currentUser.id === playerStateFromDb.current.manager ||
        playerStateFromDb.current.paused ||
        !playerRef.current
      ) {
        healthCount.current = 0;
        return;
      }
      const secondsElapsed = Math.round(playerRef.current.getCurrentTime());
      if (
        Math.abs(secondsElapsed - playerStateFromDb.current.secondsElapsed) > 19
      ) {
        healthCount.current += 1;
      } else {
        healthCount.current = 0;
      }
      if (healthCount.current === 30) {
        toast({
          render: () => (
            <Toast>Looks like the DJ is inactive. Changing...</Toast>
          ),
        });
        currentRoom.playerState = playerStateFromDb.current;
        await removeGuest(playerStateFromDb.current.manager, currentRoom);
      }
    }, 1000);

    return () => {
      clearInterval(controlInterval);
      clearInterval(healthCheckingInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentRoom.type === "bar") return;
    playerStateFromDb.current = {
      ...playerStateFromDb.current,
      manager: currentRoom.playerState.manager,
      listeners: currentRoom.playerState.listeners,
    };
    if (currentUser.id === playerStateFromDb.current.manager) return;
    if (!playerRef.current) return;
    if (currentRoom.playerState.paused) {
      toast({
        render: () => <Toast>The DJ paused the music.</Toast>,
      });
      playerRef.current.pauseVideo();
      playerRef.current.seekTo(currentRoom.playerState.secondsElapsed);
    } else {
      playerRef.current.playVideo();
    }
    const secondsElapsed = Math.round(playerRef.current.getCurrentTime());
    playerStateFromDb.current = currentRoom.playerState;
    if (
      Math.abs(secondsElapsed - currentRoom.playerState.secondsElapsed) > 19
    ) {
      healthCount.current += 1;
      playerRef.current.seekTo(currentRoom.playerState.secondsElapsed);
      toast({
        render: () => <Toast>We're syncing the song to the DJ.</Toast>,
      });
    } else {
      healthCount.current = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoom.playerState, playerRef.current]);

  const onReadyHandler = async (event: any) => {
    playerRef.current = event.target;
    playerRef.current.seekTo(currentRoom?.playerState.secondsElapsed ?? 0);
    if (!currentRoom.playerState.paused) {
      play();
    }
  };

  const onStateChangeHandler = (event: any) => {
    if (
      event.data === PlayerStates.ENDED &&
      currentUser.id !== playerStateFromDb.current.manager
    ) {
      playerRef.current.seekTo(playerRef.current.getDuration() - 10);
      playerRef.current.pauseVideo();
      toast({
        render: () => <Toast>Waiting for the DJ to change the music.</Toast>,
      });
    }
    if (event.data === PlayerStates.OTHER) {
      play();
    }
  };

  const play = async () => {
    playerRef.current.playVideo();
    playerRef.current.seekTo(currentRoom?.playerState.secondsElapsed);
  };

  const onEndMusicHandler = async () => {
    if (currentUser.id === playerStateFromDb.current.manager)
      await skipMusic(currentRoom!);
  };

  return (
    <div className="player">
      <YouTube
        videoId={music.youtubeId}
        opts={opts}
        onReady={onReadyHandler}
        onStateChange={onStateChangeHandler}
        onEnd={() => onEndMusicHandler()}
      />
    </div>
  );
};
