import { useAuthUser, getOrCreateUser } from "@/services/authentication";
import { updateGuests } from "@/services/room";
import { getUserSavedMusics } from "@/services/user";
import { useStoreState, useStoreActions } from "@/store";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { LoginButtons } from "../Login/LoginButtons";
import { Toast } from "../Toast";

type LoginModalProps = {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const LoginModal = ({ title, isOpen, onClose }: LoginModalProps) => {
  const currentRoom = useStoreState((state) => state.currentRoom.value);
  const currentUser = useStoreState((state) => state.currentUser.value);
  const setUser = useStoreActions((action) => action.currentUser.setUser);
  const setSavedMusics = useStoreActions(
    (action) => action.savedMusics.setSavedMusics
  );
  const toast = useToast();

  useAuthUser(
    async (user) => {
      if (!currentRoom || !currentUser || !currentUser.isGuest) return;
      const userFromFirestore = await getOrCreateUser(user, currentUser);

      if (!userFromFirestore) {
        return toast({
          render: () => <Toast>Couldn't get or create user.</Toast>,
        });
      }
      setUser({ ...userFromFirestore });

      const savedMusics = await getUserSavedMusics(userFromFirestore.id);
      setSavedMusics([...savedMusics]);
      const guests = [...currentRoom.guests].reverse();
      if (!guests.includes(userFromFirestore.id)) {
        guests.push(userFromFirestore.id);
      }
      if (currentUser.id !== userFromFirestore.id) {
        guests.splice(guests.indexOf(currentUser.id), 1);
      }
      await updateGuests(currentRoom.id, guests);
      onClose();
    },
    () => {}
  );

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent bgColor="purple.900">
        <ModalHeader>Sign In</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {title && (
            <Text textAlign="center" mb="2">
              {title}
            </Text>
          )}
          <LoginButtons />
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
