import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
  Stack,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { MinusIcon, SmallAddIcon } from "@chakra-ui/icons";
import { subscribeToUserUpdates } from "@/services/user";
import { useStoreActions } from "@/store";
import { Room } from "@/types/room";
import { User } from "@/types/user";
import { Toast } from "../Toast";
import { TokensPaymentForm } from "../TokensPaymentForm";

type PayTokensModalProps = {
  isOpen: boolean;
  user: User;
  room: Pick<Room, "tokenPrice" | "id">;
  onClose: () => void;
};

const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? "pk_live_rJBxCS34TPDZuQNJZNIUUkaM00exP7KUdk"
    : "pk_test_SXdBkm8HcUBAftGyfcFjX9yg00ohkE7tVO"
);

export const PayTokensModal = ({
  isOpen,
  onClose,
  user,
  room,
}: PayTokensModalProps) => {
  const [totalTokens, setTotalTokens] = useState(1);
  const { setUser } = useStoreActions((action) => action.currentUser);

  const toast = useToast({
    position: "bottom",
  });

  useEffect(() => {
    if (!isOpen) return;
    const currentTokenCount = user.tokens[room.id] ?? 0;
    const unsubscribeRoom = subscribeToUserUpdates(
      user.id,
      async (newUser: User) => {
        const newTotalTokens = newUser.tokens[room.id] ?? 0;
        if (newTotalTokens !== currentTokenCount) {
          setUser(newUser);
          toast({
            render: () => (
              <Toast type="success">
                You just received {newTotalTokens} tokens 💰
              </Toast>
            ),
          });
          onClose();
        }
      }
    );
    return unsubscribeRoom;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handlePaymentDone = (error?: string) => {
    if (error)
      toast({
        render: () => <Toast type="error">Couldn't process payment</Toast>,
      });
  };

  const onAddClick = (e: any) => {
    e.preventDefault();
    setTotalTokens(totalTokens + 1);
  };
  const onRemoveClick = (e: any) => {
    e.preventDefault();
    setTotalTokens(totalTokens - 1);
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent bgColor="purple.900">
        <ModalHeader>Buy musics</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack} spacing={6}>
          <HStack mx="auto" spacing={10}>
            <IconButton
              icon={<MinusIcon />}
              aria-label="less"
              onClick={onRemoveClick}
              isDisabled={totalTokens === 1}
            />
            <Text fontSize="40px">{totalTokens}</Text>

            <IconButton
              icon={<SmallAddIcon boxSize="20px" />}
              aria-label="more"
              onClick={onAddClick}
            />
          </HStack>

          <Elements stripe={stripePromise}>
            <TokensPaymentForm
              onPaymentDone={handlePaymentDone}
              totalTokens={totalTokens}
              price={room.tokenPrice}
              userId={user.id}
              roomId={room.id}
            />
          </Elements>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
