import { Http } from "@capacitor-community/http";

export const searchMusicsNativeRequest = (query: string) =>
  Http.request({
    method: "POST",
    url: "https://music.youtube.com/youtubei/v1/search?alt=json&key=AIzaSyC9XL3ZjWddXya6X74dJoCTL-WEYFDNX30",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      "User-Agent":
        "Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)",
      origin: "https://music.youtube.com",
    },
    data: {
      query,
      params: "EgWKAQIIAWoKEAoQCRADEAQQBQ%3D%3D",
      context: {
        client: {
          clientName: "WEB_REMIX",
          clientVersion: "0.1",
        },
      },
    },
  });

export const searchPlaylistsNativeRequest = (query: string) =>
  Http.request({
    method: "POST",
    url: "https://music.youtube.com/youtubei/v1/search?alt=json&key=AIzaSyC9XL3ZjWddXya6X74dJoCTL-WEYFDNX30",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      "User-Agent":
        "Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)",
      origin: "https://music.youtube.com",
    },
    data: {
      query,
      params: "EgWKAQIoAWoKEAoQAxAEEAUQCQ%3D%3D",
      context: {
        client: {
          clientName: "WEB_REMIX",
          clientVersion: "0.1",
        },
      },
    },
  });

export const suggestionsNativeRequest = (id: string) =>
  Http.request({
    method: "POST",
    url: "https://music.youtube.com/youtubei/v1/next?alt=json&key=AIzaSyC9XL3ZjWddXya6X74dJoCTL-WEYFDNX30",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      "User-Agent":
        "Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)",
      origin: "https://music.youtube.com",
    },
    data: {
      enablePersistentPlaylistPanel: true,
      isAudioOnly: true,
      params: "mgMDCNgE",
      playerParams: "igMDCNgE",
      tunerSettingValue: "AUTOMIX_SETTING_NORMAL",
      videoId: id,
      context: {
        client: {
          clientName: "WEB_REMIX",
          clientVersion: "0.1",
        },
      },
    },
  });

export const listMusicsFromPlaylistNativeRequest = (playlistId: string) =>
  Http.request({
    method: "POST",
    url: "https://music.youtube.com/youtubei/v1/browse?alt=json&key=AIzaSyC9XL3ZjWddXya6X74dJoCTL-WEYFDNX30",
    headers: {
      "Content-Type": "application/json",
      Connection: "keep-alive",
      "User-Agent":
        "Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)",
      origin: "https://music.youtube.com",
    },
    data: {
      browseId: playlistId,
      context: {
        client: {
          clientName: "WEB_REMIX",
          clientVersion: "0.1",
        },
      },
    },
  });
