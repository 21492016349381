import React, { useRef } from "react";
import { Image, ImageProps } from "@chakra-ui/react";

export const FadeInImage = (props: ImageProps) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  return (
    <Image
      alt={props.alt}
      {...props}
      ref={imageRef}
      onLoad={() => (imageRef.current!.style.opacity = "1")}
      transition="opacity 100ms"
      opacity={0}
    />
  );
};
