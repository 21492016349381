import React, { useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useDebounce } from "use-debounce";
import { captureException } from "@sentry/nextjs";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useStoreActions, useStoreState } from "@/store";
import { Music } from "@/types/room";
import { User } from "@/types/user";
import { LogOutIcon } from "@/assets/icons/LogOutIcon";
import { MusicNoteIcon } from "@/assets/icons/MusicNoteIcon";
import { PageLoading } from "@/components/PageLoading";
import { MusicList } from "@/components/Playlist/music/MusicList";
import { Player } from "@/components/Playlist/music/Player";
import { SearchInput } from "@/components/Playlist/search/SearchInput";
import { SearchResults } from "@/components/Playlist/search/SearchResults";
import { SocialSection } from "@/components/Playlist/SocialSection";
import { SuggestionList } from "@/components/Playlist/SuggestionList";
import {
  delegateManagement,
  removeMusicListener,
  takeManagement,
  addMusicListener,
} from "@/services/player";
import {
  subscribeToRoomUpdates,
  parseRoomFromFirebase,
  flushOtherGuests,
  addMusicToPlaylist,
  playlistHasMusics,
} from "@/services/room";
import {
  parseMusicFromMusicVideo,
  scrapYoutubeSuggestionsFromId,
} from "@/services/search";
import { isDesktopBrowser } from "@/services/utils";

export const Playlist: React.FC = () => {
  const router = useRouter();
  const currentRoom = useStoreState((state) => state.currentRoom.value);
  const setRoom = useStoreActions((actions) => actions.currentRoom.setRoom);
  const currentSuggestions = useStoreState(
    (state) => state.currentSuggestions.value
  );
  const { setSuggestions } = useStoreActions(
    (action) => action.currentSuggestions
  );
  const currentUser = useStoreState((state) => state.currentUser.value);
  const [pageLoading, setPageLoading] = useState(true);
  const [displayPlayer, setDisplayPlayer] = useState(false);
  const [currentTitle, setCurrentTitle] = useState<string | undefined>();
  const setClearSearchOrder = useStoreActions(
    (actions) => actions.clearSearchOrder.setClearSearchOrder
  );
  const {
    onOpen: onHomeAlertOpen,
    onClose: onHomeAlertClose,
    isOpen: isHomeAlertClose,
  } = useDisclosure();
  const currentUserRef = useRef<User | undefined>();

  const cancelRef = useRef<any>(null);

  const [debouncedCurrentTitle] = useDebounce(currentTitle, 5000);

  const [innerWidth, setInnerWidth] = useState(0);

  const totalTokens = currentUser?.tokens[currentRoom?.id ?? ""] ?? 0;

  useEffect(() => {
    return handleWindowResize();
  }, []);

  useEffect(() => {
    currentUserRef.current = currentUser;
  }, [currentUser]);

  useEffect(() => {
    refreshSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCurrentTitle]);

  useEffect(() => {
    if (!router.isReady) return;
    if (!currentUser || !currentRoom || currentRoom.id !== router.query.id) {
      setRoom(undefined);
      router.replace(`/${router.query.id}/welcome`);
      return;
    }
    if (isDesktopBrowser() && localStorage.getItem("clientDownloaded")) {
      const a = document.createElement("a");
      a.href = `jamhouse://${router.query.id}`;
      a.click();
    }
    if (currentRoom.playerState.listeners.includes(currentUser.id)) {
      setDisplayPlayer(true);
    }
    const unsubscribeRoom = subscribeToRoomUpdates(
      currentRoom.id,
      handleRoomUpdate
    );
    setPageLoading(false);
    return unsubscribeRoom;
    // eslint-disable-next-line
  }, [router.isReady]);

  useEffect(() => {
    if (!currentRoom) return;
    if ((currentRoom.playlist.length ?? 0) === 0) return;
    const topTitle = currentRoom.playlist[0].title;
    if (currentTitle === topTitle) return;
    setCurrentTitle(topTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoom?.playlist]);

  const handleRoomUpdate = async (newRoom: any) => {
    if (!currentUserRef.current) return;
    const room = parseRoomFromFirebase(newRoom)!;
    const currentUserKickedOut = !room.guests.includes(
      currentUserRef.current.id
    );
    if (currentUserKickedOut) return router.replace("/me");
    const twoHoursBefore = new Date();
    twoHoursBefore.setHours(twoHoursBefore.getHours() - 2);
    const roomIsInactive = room.modifiedDate <= twoHoursBefore;
    if (roomIsInactive) {
      await flushOtherGuests(room.id, currentUserRef.current.id, room.type);
      room.guests = [currentUserRef.current.id];
    }
    setRoom(room);
  };

  const handleWindowResize = () => {
    setInnerWidth(window.innerWidth);
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  };

  const refreshSuggestions = async () => {
    if (currentRoom && currentRoom.playlist.length > 0) {
      const suggestions = await getSuggestions();
      let filteredSuggestions = suggestions.filter(
        (music: any) =>
          currentRoom.playlist?.findIndex(
            (item) => item.youtubeId === music.youtubeId
          ) === -1
      );
      if (filteredSuggestions.length === 0) {
        filteredSuggestions = currentSuggestions ?? [];
      }
      setSuggestions(filteredSuggestions);
      if (
        currentRoom.playlist.length === 1 &&
        currentRoom.playerState.manager === currentUser?.id &&
        !router.pathname.includes("/search")
      ) {
        const randomIndexBetweenZeroAndFive = Math.round(
          (Math.random() / 2) * 10
        );
        const musicToAdd = parseMusicFromMusicVideo(
          filteredSuggestions[randomIndexBetweenZeroAndFive]
        ) as Music;
        (async () => {
          try {
            await addMusicToPlaylist(currentRoom, musicToAdd);
          } catch (e) {
            captureException(e);
          }
        })();
      }
    }
  };

  const getSuggestions = async () => {
    if (!currentRoom) return [];
    const suggestionsResult = await scrapYoutubeSuggestionsFromId(
      currentRoom.playlist[0].youtubeId
    );
    suggestionsResult.shift();
    return suggestionsResult;
  };

  const handleBackClick = () => {
    if (router.query.playlistId) {
      return router.push({
        pathname: `/[id]/search`,
        query: { ...router.query, playlistId: [] },
      });
    }
    router.replace(`/${router.query.id}`);
    setClearSearchOrder(true);
  };

  const handleHomeClick = () => router.push(`/me`);

  const handleListenToMusicChange = async () => {
    if (!currentUser || !currentRoom) return;
    setDisplayPlayer(!displayPlayer);
    if (displayPlayer) {
      const isManagerAndHaveOtherListeners =
        currentUser.id === currentRoom.playerState.manager &&
        currentRoom.playerState.listeners.length >= 2;
      if (isManagerAndHaveOtherListeners) {
        const newManager = [
          ...currentRoom.playerState.listeners.filter(
            (listener) => listener !== currentUser.id
          ),
        ].pop();
        if (!newManager) return;
        return delegateManagement(currentRoom.id, currentUser.id, newManager);
      }
      return removeMusicListener(currentRoom.id, currentUser.id);
    }
    const isRoomOwner = currentRoom.owner === currentUser.id;
    if (isRoomOwner) {
      return takeManagement(currentRoom.id, currentUser.id);
    }
    return addMusicListener(currentRoom.id, currentUser.id);
  };

  if (pageLoading || !currentRoom || !currentUser) {
    return <PageLoading />;
  }
  return (
    <Box
      height="100vh"
      style={{ paddingTop: "calc(env(safe-area-inset-top) + 20px)" }}
      px={4}
    >
      <Flex flex="1" justifyContent="center">
        <Flex
          justifyContent="space-between"
          w="full"
          maxW={!displayPlayer ? "xl" : "unset"}
          mb={2}
        >
          {router.pathname.includes("/search") ? (
            <IconButton
              icon={<ArrowBackIcon fontSize="25px" />}
              aria-label="back"
              size="lg"
              variant="ghost"
              onClick={handleBackClick}
            />
          ) : (
            <>
              {(!currentUser.isGuest || currentRoom.type !== "bar") && (
                <IconButton
                  icon={<LogOutIcon fontSize="25px" />}
                  aria-label="back"
                  size="lg"
                  variant="ghost"
                  onClick={() => {
                    if (displayPlayer) {
                      return onHomeAlertOpen();
                    }
                    handleHomeClick();
                  }}
                />
              )}
              <AlertDialog
                motionPreset="slideInBottom"
                onClose={onHomeAlertClose}
                isOpen={isHomeAlertClose}
                isCentered
                leastDestructiveRef={cancelRef}
              >
                <AlertDialogOverlay />

                <AlertDialogContent>
                  <AlertDialogHeader>Sure you want to leave?</AlertDialogHeader>
                  <AlertDialogCloseButton />
                  <AlertDialogBody>
                    You will no longer listen to the music.
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onHomeAlertClose}>
                      No
                    </Button>
                    <Button colorScheme="red" ml={3} onClick={handleHomeClick}>
                      Yes
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </>
          )}
          {currentRoom.type === "flat" &&
            playlistHasMusics(currentRoom.playlist) && (
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <FormLabel htmlFor="activate-player" m={0} mr={2}>
                  Listen to music
                </FormLabel>
                <Switch
                  id="activate-player"
                  size="lg"
                  colorScheme="purple"
                  isChecked={displayPlayer}
                  onChange={handleListenToMusicChange}
                />
              </FormControl>
            )}
        </Flex>
      </Flex>
      {!currentUser.isGuest && (
        <Flex
          position="relative"
          mb={4}
          display={router.pathname.includes("/search") ? "none" : "block"}
        >
          <SocialSection
            ownerId={currentRoom.owner}
            guestsId={currentRoom.guests}
            maxGuests={currentRoom.maxGuests}
          />
        </Flex>
      )}
      <Flex
        direction={{ base: "column", lg: "row" }}
        justifyContent={{ lg: "center" }}
        alignItems={{ lg: "normal" }}
      >
        {innerWidth <= 990 && router.pathname.includes("/search") && (
          <SearchInput />
        )}

        {playlistHasMusics(currentRoom.playlist) && displayPlayer && (
          <Box
            flex="1"
            w={{ base: router.pathname.includes("/search") ? "0" : "full" }}
            mb={router.pathname.includes("/search") ? 0 : 4}
            mr={{ base: 0, sm: 4 }}
          >
            <Player music={currentRoom.playlist[0]} />
          </Box>
        )}

        <Flex direction="column" flex="1" maxWidth={{ lg: "xl" }}>
          {innerWidth > 990 && router.pathname.includes("/search") && (
            <SearchInput />
          )}
          {totalTokens > 0 && (
            <HStack mb="4" mt="4">
              <Text>You currently have {totalTokens} tokens</Text>
              <MusicNoteIcon fontSize="20px" />
            </HStack>
          )}
          <Flex direction="column" pb="env(safe-area-inset-bottom)">
            {router.pathname.includes("/search") && <SearchResults />}

            <Box
              display={router.pathname.includes("/search") ? "none" : "block"}
            >
              <MusicList
                room={currentRoom}
                showUpgradeButton={
                  currentUser.id === currentRoom.owner &&
                  currentUser.plan !== "pro" &&
                  currentRoom.type === "flat"
                }
              />
            </Box>

            {playlistHasMusics(currentRoom.playlist) &&
              !router.pathname.includes("/search") && <SuggestionList />}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Playlist;
