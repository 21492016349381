import {
  Button,
  Flex,
  Heading,
  LightMode,
  Stack,
  Tag,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { PaymentModal } from "@/components/modals/PaymentModal";
import { useStoreState } from "@/store";
import { Room, Music } from "@/types/room";
import { MusicItem, MUSIC_ITEM_TYPE } from "./MusicItem";

type Props = {
  room: Room;
  showUpgradeButton: boolean;
};
export const MusicList = ({ room, showUpgradeButton }: Props) => {
  const playlist: Music[] = useStoreState(
    (state) => state.currentRoom.value!!.playlist
  );
  const currentUserURL = useStoreState(
    (state) => state.currentUser.value!!.photoUrl
  );
  const [musicItems, setMusicItems] = useState<JSX.Element[]>([]);

  useEffect(() => {
    setMusicItems(
      playlist.map((music: Music, index: number) => {
        if (index === 0) {
          return (
            <MusicItem
              music={music}
              type={MUSIC_ITEM_TYPE.PLAYLIST}
              key={music.youtubeId}
              isCurrentlyPlaying={true}
            />
          );
        }
        return (
          <MusicItem
            music={music}
            type={MUSIC_ITEM_TYPE.PLAYLIST}
            key={music.youtubeId}
            isCurrentlyPlaying={false}
          />
        );
      })
    );
  }, [setMusicItems, playlist, currentUserURL]);

  const title = room.name ? `${room.name}'s playlist` : `Playlist`;

  return (
    <>
      <Flex mb={4} justify="space-between" align="center">
        <Heading>{title}</Heading>
        {showUpgradeButton && <UpgradeButton />}
      </Flex>
      <Stack flexDir="column" spacing={4} mb={4}>
        <LightMode>
          <Link href={`/${room.id}/search`} passHref>
            <Button
              as="a"
              colorScheme="orange"
              mb={4}
              size="lg"
              boxShadow="0px 0px 20px 10px rgba(252,106,3,0.4);"
            >
              Add a music
            </Button>
          </Link>
        </LightMode>
        {musicItems}
      </Stack>
    </>
  );
};

const UpgradeButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Button rightIcon={<Tag colorScheme="purple">Pro</Tag>} onClick={onOpen}>
      Upgrade
      <PaymentModal isOpen={isOpen} onClose={onClose} />
    </Button>
  );
};
