const axios = require("axios").default;
let jsonpAdapter = require("axios-jsonp");
import { Capacitor } from "@capacitor/core";
import { isElectron } from "./utils";
import { captureException } from "@sentry/nextjs";
import {
  searchPlaylistsNativeRequest,
  listMusicsFromPlaylistNativeRequest,
  searchMusicsNativeRequest,
  suggestionsNativeRequest,
} from "@/libs/ytMusic";
import { parseListMusicsFromPlaylistBody } from "@/libs/ytMusic/listMusicsFromPlaylist";
import { MusicVideo, PlaylistPreview } from "@/libs/ytMusic/models";
import { parseSearchMusicsBody } from "@/libs/ytMusic/searchMusics";
import { parseSearchPlaylistsBody } from "@/libs/ytMusic/searchPlaylists";
import { parseGetSuggestionsBody } from "@/libs/ytMusic/suggestions";
import { Music } from "@/types/room";

let baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
if (isElectron()) {
  baseUrl = `http://localhost:7999`;
}

export const getYoutubeSearchResults = async (
  searchInput: string
): Promise<MusicVideo[]> => scrapYoutubeSearchResults(searchInput);

export const getGoogleSuggestions = async (searchInput: string) => {
  const results = await axios({
    url: `https://suggestqueries.google.com/complete/search?client=chrome&ds=yt&q=${searchInput}`,
    adapter: jsonpAdapter,
  });
  return results.data[1];
};

export const searchPlaylists = async (
  searchInput: string
): Promise<PlaylistPreview[]> => {
  if (Capacitor.isNativePlatform()) {
    const ret = await searchPlaylistsNativeRequest(searchInput);
    try {
      const results = parseSearchPlaylistsBody(ret.data, true);
      if (!results) return [];
      return results.filter(nonEmptyPlaylist) as PlaylistPreview[];
    } catch (e) {
      captureException(e);
      console.log(e);
      return [];
    }
  }
  const response = await fetch(`${baseUrl}/playlists?search=${searchInput}`);
  const results = await response.json();
  return results.filter(nonEmptyPlaylist);
};

const nonEmptyPlaylist = (playlistItem: any) =>
  playlistItem.thumbnailUrl?.includes("https://") && playlistItem.playlistId;

export const listMusicsFromPlaylist = async (
  playlistId: string
): Promise<MusicVideo[]> => {
  if (Capacitor.isNativePlatform()) {
    const ret = await listMusicsFromPlaylistNativeRequest(playlistId);
    try {
      const results = parseListMusicsFromPlaylistBody(ret.data);
      if (!results) return [];
      return results.filter(nonEmptySong) as MusicVideo[];
    } catch (e) {
      captureException(e);
      console.log(e);
      return [];
    }
  }
  const response = await fetch(`${baseUrl}/playlists/${playlistId}/musics`);

  const results = await response.json();
  return results.filter(nonEmptySong);
};

export const scrapYoutubeSearchResults = async (
  searchInput: string
): Promise<MusicVideo[]> => {
  if (Capacitor.isNativePlatform()) {
    const ret = await searchMusicsNativeRequest(searchInput);
    try {
      const results = parseSearchMusicsBody(ret.data);
      return results.filter(nonEmptySong) as MusicVideo[];
    } catch (e) {
      captureException(e);
      console.log(e);
      return [];
    }
  }
  const response = await fetch(`${baseUrl}/musics?search=${searchInput}`);

  const results = await response.json();
  return results.filter(nonEmptySong);
};

export const scrapYoutubeSuggestionsFromId = async (
  id: string
): Promise<MusicVideo[]> => {
  if (Capacitor.isNativePlatform()) {
    const ret = await suggestionsNativeRequest(id);
    try {
      const results = parseGetSuggestionsBody(ret.data);
      return results.filter(nonEmptySong) as MusicVideo[];
    } catch (e) {
      captureException(e);
      console.log(e);
      return [];
    }
  }
  const response = await fetch(`${baseUrl}/suggestions?id=${id}`);
  const results = await response.json();
  return results.filter(nonEmptySong);
};

const nonEmptySong = (musicItem: any) =>
  musicItem.thumbnailUrl?.includes("https://") &&
  musicItem.youtubeId &&
  musicItem.title;

export const parseMusicFromMusicVideo = (
  musicVideo: MusicVideo
): Music | null => ({
  artist: musicVideo.artist ?? "",
  thumbnailUrl: musicVideo.thumbnailUrl ?? "",
  title: musicVideo.title ?? "",
  youtubeId: musicVideo.youtubeId ?? "",
  upVotes: [],
  skipVotes: [],
});

export const searchForSpotifyPlaylists = async (
  search: string
): Promise<PlaylistPreview[]> => {
  const response = await fetch(
    `${baseUrl}/spotify/playlists/search?search=${encodeURIComponent(search)}`
  );
  const data = await response.json();
  console.log(data);
  return data.map(deserializeSpotifyPlaylistsSearchResults);
};

export const listSpotifyPlaylistMusics = async (
  playlistId: string
): Promise<MusicVideo[]> => {
  const response = await fetch(`${baseUrl}/spotify/playlists/${playlistId}`);
  const results = await response.json();
  return results.filter(nonEmptySong);
};

const deserializeSpotifyPlaylistsSearchResults = (
  playlist: any
): PlaylistPreview => ({
  playlistId: playlist.id,
  thumbnailUrl: playlist.images[0].url,
  title: playlist.name,
  totalSongs: playlist.tracks.total,
});

export const extractIdFromSpotifyUrl = (url: string): string =>
  url.split("/").pop()?.split("?")[0] ?? "";
